export const mapApiKey = 'AIzaSyAPwBTOlY6LEsFVk1e8rSjXN7NHlwEFsEk';
export const mapIds = ['c0d333219a2e47d5'];
export const mapId = 'c0d333219a2e47d5';
export const libraries: (
  | 'places'
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'visualization'
)[] = ['places'];
export enum TravelMode {
  BICYCLING = 'BICYCLING',
  DRIVING = 'DRIVING',
  TRANSIT = 'TRANSIT',
  WALKING = 'WALKING',
}
