'use client';
import { Text } from '@chakra-ui/react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useRouter, useSearchParams } from 'next/navigation';
import React, { Suspense, useEffect, useState } from 'react';

import { MapWrapper } from '@/app/_components/map-wrapper/map-wrapper';
import { mapId } from '@/constants/maps/maps.constants';
import {
  useDealersInfo,
  useGeoLocation,
  useSelectedDealerLocation,
} from '@/hooks/dealers/dealers.hook';
import { useFloatingCTA } from '@/hooks/floating-cta.hook';
import { useRoutesConfig } from '@/hooks/routes-config';
import {
  getCurrentLocation,
  sortByDistance,
} from '@/services/location.service';
import { DealerItem } from '@/types/dealers/dealers.types';
import { PanelbeaterItem } from '@/types/panelbeaters/panelbeaters.types';

import styles from './Dealers.module.scss';
import { FindService } from './FindService';

const containerStyle = {
  width: '100%',
  height: '700px',
};

const FindServicePage = ({
  services,
  type,
}: {
  services: DealerItem[] | PanelbeaterItem[];
  type: 'panelbeater' | 'dealer';
}) => {
  const { setIsCTAShown } = useFloatingCTA();
  const { setDealersInfo } = useDealersInfo();

  const { userLocation, placeLocation } = useGeoLocation();

  const { selectedLocation, setSelectedLocation } = useSelectedDealerLocation();

  const [mapTypeId, setMapTypeId] = useState('');
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  useEffect(() => {
    setIsCTAShown(false);
    let location;

    if (placeLocation && placeLocation.lat && placeLocation.lng) {
      location = placeLocation;
    } else if (userLocation && userLocation.lat && userLocation.lng) {
      // if we're using the user location from the browser, recalculate the driving distances
      location = userLocation;
    } else if (selectedLocation) {
      location = selectedLocation;
    } else {
      return;
    }

    const sortedDealerships = sortByDistance(location, services);

    setSelectedLocation({
      lat: location.lat,
      lng: location.lng,
      id: '',
    });

    setDealersInfo(sortedDealerships);
  }, [userLocation, placeLocation, services]);

  useEffect(() => {
    if (selectedLocation) {
      const sortedDealerships = sortByDistance(selectedLocation, services);

      setDealersInfo(sortedDealerships);
    }
  }, [selectedLocation, services]);

  return (
    <div className={styles.container}>
      <Text className={styles.visuallyHidden} as="h1">
        Find your nearest Kia Dealer
      </Text>
      <Suspense fallback={null}>
        <InitialQueryInfoDealer services={services} />
      </Suspense>

      <MapWrapper>
        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={10}
          mapTypeId={mapTypeId}
          center={selectedLocation}
          options={{ mapId }}
          onLoad={() => {
            setMapTypeId('terrain');
            setIsMapLoaded(true);
          }}
        >
          {isMapLoaded &&
            services.map(({ lat, lng, id }, index) => (
              <Marker
                position={{ lat, lng }}
                key={index}
                icon={
                  selectedLocation.lat === lat && selectedLocation.lng === lng
                    ? 'location-icon-active.svg'
                    : 'location-icon.svg'
                }
                onClick={() => setSelectedLocation({ lat, lng, id })}
              />
            ))}
        </GoogleMap>
      </MapWrapper>

      <FindService type={type} loaded={isMapLoaded} services={services} />
    </div>
  );
};

function InitialQueryInfoDealer({ services }: { services: any[] }) {
  const { setDealersInfo } = useDealersInfo();
  const { setUserLocation } = useGeoLocation();
  const { setSelectedLocation } = useSelectedDealerLocation();
  const router = useRouter();
  const routes = useRoutesConfig();
  const searchParams = useSearchParams();

  useEffect(() => {
    setDealersInfo(services);
    if (searchParams) {
      getCurrentLocation()
        .then(({ lat, lng }) => {
          if (lat && lng) {
            setUserLocation({ lat, lng });
            setSelectedLocation({ lat, lng, id: '' });
          }
        })
        .catch(() => {});
    } else {
      router.replace(routes.findDealer.path, undefined);
    }
  }, []);

  return null;
}

export default FindServicePage;
