export const PANELBEATERS_REQUEST_FIELDS = [
  'id',
  'name',
  'address',
  'latitude',
  'longitude',
  'province.name',
  'contact_number',
  'location',
];

export const panelbeaterLocation = {
  lat: -26.126461,
  lng: 27.846852,
};
