import { Box, Container } from '@chakra-ui/layout';
import { Autocomplete } from '@react-google-maps/api';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import { DealersList } from '@/app/find-a-dealer/_components/dealership-locations/DealersList';
import { SearchLocations } from '@/app/find-a-dealer/_components/search-dealers/SearchLocations';
import { PanelBeaterList } from '@/app/panelbeaters/panelBeatersList';
import { desktop } from '@/constants/vars.constants';
import {
  useDealersInfo,
  useGeoLocation,
  useSelectedDealerLocation,
} from '@/hooks/dealers/dealers.hook';
import { useWindowSize } from '@/hooks/window-size.hook';
import { DealerItem } from '@/types/dealers/dealers.types';
import { PanelbeaterItem } from '@/types/panelbeaters/panelbeaters.types';

import styles from './Dealers.module.scss';

export const FindService = ({
  type,
  loaded,
  services,
}: {
  type: 'panelbeater' | 'dealer';
  loaded: boolean;
  services: DealerItem[] | PanelbeaterItem[];
}) => {
  const size = useWindowSize();
  const isMobile = Boolean(parseInt(size?.width + '') < desktop);

  const { dealersInfo } = useDealersInfo();
  const { placeLocation, setPlaceLocation } = useGeoLocation();
  const { selectedLocation } = useSelectedDealerLocation();
  const allDealers = dealersInfo.length ? dealersInfo : services;

  const [searchResults, setSearchResults] = useState<(DealerItem | PanelbeaterItem)[]>(allDealers);

  const [autocomplete, setAutocomplete] =
    // eslint-disable-next-line no-undef
    useState<google.maps.places.Autocomplete>();

  useEffect(() => {
    setSearchResults(dealersInfo);
  }, [dealersInfo]);

  const handlePlaceSelected = () => {
    const googlePlace = autocomplete?.getPlace();

    if (googlePlace) {
      setPlaceLocation({
        name: googlePlace.formatted_address || '',
        lat: googlePlace.geometry?.location?.lat() || 0,
        lng: googlePlace.geometry?.location?.lng() || 0,
      });
    }
  };

  const handleSearch = (search: string) => {
    setSearchResults(allDealers.filter(dealer => dealer.name.toLowerCase().includes(search.toLowerCase())))
  }

  return (
    <Container maxW={'100%'} className={styles.dealersContainer} paddingX={0}>
      <Box
        className={cn(styles.dealersWrapper, {
          [styles.showDealerOnly as string]:
            selectedLocation?.id && selectedLocation?.id?.toString().length,
        })}
        maxW={{ base: '100%', md: '450' }}
        width={'100%'}
      >
        {loaded ? (
          <Autocomplete
            onLoad={(component) => component && setAutocomplete(component)}
            onPlaceChanged={handlePlaceSelected}
            className={cn({
              [styles.hideSearch as string]:
                isMobile && selectedLocation?.id !== '',
            })}
          >
            <SearchLocations
              handleSearch={handleSearch}
              initialValue={placeLocation.name}
            />
          </Autocomplete>
        ) : (
          <SearchLocations
            handleSearch={handleSearch}
            initialValue={placeLocation.name}
          />
        )}
        {type === 'panelbeater' ? (
          <PanelBeaterList
            panelBeaters={searchResults as PanelbeaterItem[]}
            isMobile={isMobile}
          />
        ) : (
          <DealersList
            dealers={searchResults as DealerItem[]}
            isMobile={isMobile}
          />
        )}
      </Box>
    </Container>
  );
};
