import { CloseIcon } from '@chakra-ui/icons';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';

import { ReactComponent as SearchIcon } from '@/app/_icons/search.svg';

import styles from './SearchDealers.module.scss';

interface Props {
  handleSearch?: (argument: string) => void;
  initialValue?: string;
}

export const SearchLocations = ({ handleSearch, initialValue }: Props) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    handleSearch && handleSearch(e.target.value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Input
      placeholder="Enter town or city"
      prefix={<SearchIcon className={styles.inputIcon} />}
      className={styles.searchDealers}
      value={value}
      onChange={handleChange}
      allowClear={{ clearIcon: <CloseIcon /> }}
    ></Input>
  );
};
